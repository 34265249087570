<template>
  <div>
    <v-app-bar app dense color="purple" dark class="overflow-visible">
      <v-app-bar-nav-icon @click="requestDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title class="pl-2">
        <v-icon class="ma-3" small @click="searching = true">mdi-magnify</v-icon>
        <v-chip class="ma-2" v-show="searchText" small @click="searchText = ''" dark text-color="grey darken-2" color="grey lighten-2"><v-icon small class="mr-2">mdi-filter-remove-outline</v-icon>
          {{ searchText }}
        </v-chip>
        <v-chip small
                label
                @click="deselectTag(tag)"
                v-for="tag in selectedTags"
                :key="tag.id"
                :color="`${tag.color} lighten-2`"
                dark
                class="ml-2">
          {{tag.text}}
          <v-icon x-small class="ml-2">$delete</v-icon>
        </v-chip>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <new-item-icon @addTaskRequested="addTaskRequested"
                     @addEventRequested="addEventRequested"
                     @addContactRequested="addContactRequested"
                     @addFileRequested="addFileRequested"></new-item-icon>
    </v-app-bar>
    <v-list>
      <draggable v-model="filteredFiles" group="files" handle=".handle">
        <v-slide-x-transition group mode="out-in">
          <file-list-item @detailsRequested="showDetails(file)" v-for="file in filteredFiles" :key="file.id" :file="file"></file-list-item>
        </v-slide-x-transition>
      </draggable>
    </v-list>
    <v-dialog max-width="400" v-model="searching">
      <v-card>
        <v-card-text>
          <v-text-field clearable @keydown.enter.prevent="searching = false" :persistent-hint="true" hint="Search for files by name" v-model="searchText" autofocus></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <file-details @editRequested="editRequested" :file="activeFile" :trigger.sync="showingFileDetails"></file-details>
  </div>
</template>

<script>
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'
import FileListItem from '@/components/listItems/FileListItem'
import FileDetails from '@/components/details/FileDetails'
import NewItemIcon from '@/components/icons/NewItemIcon'
import TagCoordinator from '@/components/services/TagCoordinator'
import draggable from 'vuedraggable'
import fileApi from '@/firebase/fileApi'

export default {
  name: 'Files',
  components: { FileDetails, FileListItem, NewItemIcon, draggable },
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('files', db.collection(`users/${user.id}/files`))
      this.user = user
    })
  },
  data: () => ({
    files: [],
    activeFile: {},
    showingFileDetails: false,
    searchText: '',
    selectedTags: TagCoordinator.selectedTags,
    searching: false
  }),
  methods: {
    deselectTag (tag) {
      TagCoordinator.deselectTag(tag)
    },
    filterFiles () {
      let files = this.files.filter(f => TagCoordinator.itemTagSelected(f))
      if (this.searchText) {
        const lowerSearch = this.searchText.toLowerCase()
        files = files.filter(f => f.title.toLowerCase().indexOf(lowerSearch) > -1)
      }
      return files.sort((a, b) => a.order < b.order ? -1 : 1)
    },
    showDetails (file) {
      this.activeFile = file
      this.showingFileDetails = true
    },
    editRequested (file) {
      this.$emit('editFile', file)
    },
    addTaskRequested () {
      this.$emit('addTaskRequested')
    },
    addEventRequested () {
      this.$emit('addEventRequested')
    },
    addContactRequested () {
      this.$emit('addContactRequested')
    },
    addFileRequested () {
      this.$emit('addFileRequested')
    },
    requestDrawer () {
      this.$emit('showDrawer')
    }
  },
  watch: {
    showingFileDetails (val) {
      if (!val) {
        this.activeFile = {}
      }
    }
  },
  computed: {
    filteredFiles: {
      get () {
        return this.filterFiles(this.selectedTags)
      },
      set (value) {
        // only called by drag-n-drop sorting
        value.forEach((f, i) => {
          this.files.find(file => f.id === file.id).order = i
        })
        this.files.forEach(f => {
          fileApi.saveFile(f)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
