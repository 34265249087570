<template>
  <v-list-item :two-line="!!file.lastSaved">
    <v-list-item-icon>
      <v-icon>{{fileIcon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title @click="requestDetailDisplay">
        {{file.title}}
      </v-list-item-title>
      <v-list-item-subtitle v-if="file.lastSaved">
        {{fileLastSavedDisplay}}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon class="handle" color="grey lighten-2">mdi-menu</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import dateTimeUtils from '@/components/services/dateTimeUtils'

export default {
  name: 'FileListItem',
  props: {
    file: Object
  },
  methods: {
    requestDetailDisplay () {
      this.$emit('detailsRequested')
    }
  },
  computed: {
    fileIcon () {
      switch (this.file.type) {
        case 'text':
          return 'mdi-note-text'
        case 'list':
          return 'mdi-view-list'
        case 'upload':
          return 'mdi-file-image'
        default:
          return 'mdi-note-text'
      }
    },
    fileLastSavedDisplay () {
      return dateTimeUtils.toDateTimeDisplay(this.file.lastSaved)
    }
  }
}
</script>

<style scoped>

</style>
