<template>
  <v-bottom-sheet max-width="600" v-model="showing" scrollable>
    <v-card>
      <v-card-title>
        <span>{{file.title}}</span>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" fab target="_blank" :href="file.uploadInfo.downloadUrl" color="primary" v-if="file.uploadInfo && file.uploadInfo.downloadUrl"><v-icon>mdi-download</v-icon></v-btn>
        <v-icon @click="showing = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <span style="white-space: pre-wrap;" v-if="file.type === 'text'">{{file.text}}</span>
        <v-list v-if="file.type === 'list'" dense>
          <v-list-item v-for="(item, i) in file.listItems" :key="i">
            <v-list-item-content>
              <v-list-item-subtitle v-if="item.key">
                {{item.key}}
              </v-list-item-subtitle>
              <v-list-item-title style="white-space: pre-wrap;">
                {{item.text}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <img alt="uploaded img"
             :src="file.uploadInfo.downloadUrl"
             v-if="file.uploadInfo && file.uploadInfo.downloadUrl && file.uploadInfo.type.indexOf('image/') > -1"
             style="max-width: 100%;">
      </v-card-text>
      <v-card-actions dark>
        <v-btn color="primary" @click="requestEdit">Edit</v-btn>
        <v-spacer></v-spacer>
        <v-dialog persistent max-width="400" v-model="confirming">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined
                   v-bind="attrs"
                   v-on="on"
                   color="error">Delete</v-btn>
          </template>
          <v-card>
            <v-card-title color="purple" dark>
              Delete File?
              <v-spacer></v-spacer>
              <v-icon>mdi-alert</v-icon>
            </v-card-title>
            <v-card-text>Are you sure you want to delete this file?</v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="deleteFile">Delete</v-btn>
              <v-spacer />
              <v-btn color="primary" @click="confirming = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import fileApi from '@/firebase/fileApi'

export default {
  name: 'FileDetails',
  props: {
    trigger: Boolean,
    file: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showing: false,
    confirming: false,
    fileDataUrl: null
  }),
  computed: {
    getFileDataUrl () {
      return fileApi.getFileDataUrl(this.file)
    }
  },
  methods: {
    requestEdit () {
      this.$emit('editRequested', this.file)
      this.showing = false
    },
    async deleteFile () {
      await fileApi.deleteFile(this.file.id)
      this.showing = false
    }
  },
  watch: {
    trigger (on) {
      this.showing = on
    },
    showing (val) {
      this.$emit('update:trigger', val)
    }
  }
}
</script>

<style scoped>

</style>
